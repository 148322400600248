import { Reducer } from 'redux'

import { IUserState, UserActionTypes } from './types'

import { OnboardingStatuses } from '@/pages/Accounts/types'
import { AgencyDocsReceivedStatus, CloseDocTypes, LANGUAGE } from '@/types'

export const initialState: IUserState = {
  language: LANGUAGE.RUSSIAN,
  ab_tests: [],
  accessToken: '',
  email: '',
  phone: '',
  fb_credential_enabled: false,
  fbc: '',
  fbp: '',
  ga_user_id: '',
  has_profile: false,
  id: null,
  isAuthorized: false,
  mailProvider: '',
  profile: {
    account_counts: {
      archived_accounts: 0,
      archived_fb: 0,
      archived_google: 0,
      archived_mytarget: 0,
      archived_tiktok: 0,
      archived_vk: 0,
      archived_yandex: 0,
      archived_yandex_promo_page: 0,
      archived_avito: 0,
      archived_telegram: 0,
      archived_vkads: 0,
      archived_vk_adsmarket: 0,
      telegram: 0,
      avito: 0,
      facebook: 0,
      google: 0,
      mytarget: 0,
      tiktok: 0,
      vk: 0,
      vkads: 0,
      vk_adsmarket: 0,
      yandex_business: 0,
      yandex_promo_page: 0,
      profi: 0,
      yandex: 0,
    },
    contract_agency_status: false,
    contract_client_status: false,
    contract_avito_status: false,
    contract_telegram_status: false,
    contract_yandex_promo_page_status: false,
    contract_vkads_status: false,
    contract_vk_adsmarket_status: false,
    agency_docs_received_status: AgencyDocsReceivedStatus.EMPTY,
    actual_address: '',
    agency: '',
    agency_clients: [],
    allow_create_accounts: {
      facebook: false,
      google: false,
      mytarget: false,
      tiktok: false,
      vk: false,
      yandex: false,
      telegram: false,
      vkads: false,
      yandex_promo_page: false,
      vk_adsmarket: false,
    },
    antiban: false,
    audiences_product_tour_is_finished: false,
    autopayment: false,
    available_plugins: [],
    balance: 0,
    business_manager_id: '',
    cashback: null,
    ceo: '',
    closedoc_type: CloseDocTypes.PAYMENT,
    connected_plugins: [],
    contract_type: '',
    created_at: '',
    creatives_sent: false,
    documents_methods: null,
    documents_status: '',
    facebook_commission_percent: 0.1,
    fb_optimizer_auth: false,
    feedgen: false,
    full_name: '',
    google_ads_email: '',
    google_ads_mcc_id: '',
    google_mc: false,
    has_mytarget_manager: false,
    has_tiktok_manager: false,
    has_yandex_agency: false,
    id: null,
    industry: '',
    inn: '',
    is_profile_agency: false,
    is_profile_client: false,
    kpp: '',
    legal_address: '',
    name: '',
    new_aitarget_offer: false,
    onboarding: OnboardingStatuses.START,
    onboarding_optimizer_is_finished: false,
    optimizer: false,
    partner_status: false,
    paying_customer: false,
    personal_phone: '',
    post_sale_onboarding: false,
    profile_checks: {
      invoice: false,
      legal: false,
      payment: false,
      policy: false,
    },
    profile_is_approved: false,
    profile_type: '',
    promocode: null,
    smart_targetings: false,
    transactions_filter_export: false,
    usn_product_tour_is_finished: false,
    website: '',
    is_last_30_days_spending: false,
    vk_shop_promo: false,
    analytics_product_tour: false,
    telegram_commission: {
      accounts_subjects: [],
      cryptocurrencies: 0.3,
      education: 0.25,
      others: 0.2,
      real_estate: 0.2,
      big_data: 0,
      mts_pro_big_data: 0.2,
      tg_commission_individual: [],
      tg_euro_rate_individual: [],
    },
    tg_analytics_product_tour: false,
    tg_analytics_banner: false,
    ads_marking_product_tour: false,
    campaign_editor_product_tour: false,
    campaign_editor_product_tour_second: false,
    campaign_editor_product_tour_third: false,
    ads_marking_success: false,
    agent_client: false,
    is_ord_ready: false,
    is_cashback_enabled: true,
    new_referral_program: false,
    okved_codes: [],
  },
  refreshToken: '',
  subscribe: false,
  telegram_enabled: false,
  tokenExpire: 0,
}

const reducer: Reducer<IUserState> = (state = initialState, action: any) => {
  switch (action.type) {
    case UserActionTypes.SET_AUTHORIZED: {
      return { ...state, isAuthorized: action.data }
    }
    case UserActionTypes.SET_ATOKEN: {
      return { ...state, accessToken: action.data }
    }
    case UserActionTypes.SET_RTOKEN: {
      return { ...state, refreshToken: action.data }
    }
    case UserActionTypes.SET_EXPIRES: {
      return { ...state, tokenExpire: action.data }
    }
    case UserActionTypes.SET_EMAIL: {
      return { ...state, email: action.data }
    }
    case UserActionTypes.SET_PHONE: {
      return { ...state, phone: action.data }
    }
    case UserActionTypes.SET_HAS_PROFILE: {
      return { ...state, has_profile: action.data }
    }
    case UserActionTypes.SET_SUBSCRIBE: {
      return { ...state, subscribe: action.data }
    }
    case UserActionTypes.SET_PROFILE: {
      return {
        ...state,
        profile: { ...state.profile, ...action.data },
      }
    }
    case UserActionTypes.SET_ALLOW_CREATE_ACCOUNTS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          allow_create_accounts: { ...action.data },
        },
      }
    }
    case UserActionTypes.CLEAR_PROFILE: {
      return {
        ...state,
        profile: initialState.profile,
      }
    }
    case UserActionTypes.SET_MAIL_PROVIDER: {
      return {
        ...state,
        mailProvider: action.data,
      }
    }
    case UserActionTypes.SET_GA_USER_ID: {
      return { ...state, ga_user_id: action.data }
    }
    case UserActionTypes.SET_FBP: {
      return { ...state, fbp: action.data }
    }
    case UserActionTypes.SET_FBC: {
      return { ...state, fbc: action.data }
    }
    case UserActionTypes.SET_AB_TESTS: {
      return { ...state, ab_tests: action.data }
    }
    case UserActionTypes.SET_AVAILABLE_PLUGINS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          available_plugins: [...action.data],
        },
      }
    }
    case UserActionTypes.REMOVE_AVAILABLE_PLUGIN: {
      const foundIndex = state.profile.available_plugins.findIndex((plugin) => plugin.slug === action.data)

      return {
        ...state,
        profile: {
          ...state.profile,
          available_plugins: [
            ...state.profile.available_plugins.slice(0, foundIndex),
            ...state.profile.available_plugins.slice(foundIndex + 1),
          ],
        },
      }
    }
    case UserActionTypes.SET_TELEGRAM: {
      return { ...state, telegram_enabled: action.data }
    }
    case UserActionTypes.SET_FB_CREDENTIAL: {
      return { ...state, fb_credential_enabled: action.data }
    }
    case UserActionTypes.SET_MYTARGET_MANAGER: {
      return {
        ...state,
        profile: {
          ...state.profile,
          has_mytarget_manager: action.data,
        },
      }
    }
    case UserActionTypes.SET_TIKTOK_MANAGER: {
      return {
        ...state,
        profile: {
          ...state.profile,
          has_tiktok_manager: action.data,
        },
      }
    }
    case UserActionTypes.SET_YANDEX_AGENCY: {
      return {
        ...state,
        profile: {
          ...state.profile,
          has_yandex_agency: action.data,
        },
      }
    }
    case UserActionTypes.SET_USER_ID: {
      return { ...state, id: action.data }
    }
    case UserActionTypes.REMOVE_CONNECTED_PLUGIN: {
      return {
        ...state,
        profile: {
          ...state.profile,
          connected_plugins: state.profile.connected_plugins.filter((plugin) => plugin.id !== action.data),
        },
      }
    }
    case UserActionTypes.SET_CONNECTED_PLUGIN: {
      return {
        ...state,
        profile: {
          ...state.profile,
          connected_plugins: [...state.profile.connected_plugins, action.data],
        },
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as userReducer }
